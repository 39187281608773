import ReactDOM from "react-dom";
import { useState, useEffect } from "react";

import { readCollection } from "client/lib/api";
import { Loader } from "../icons/loader";
import { Eth } from "../icons/eth";

const CollectionHover = ({
  label,
  collectionId,
  containerRef,
  posTop = 130,
  posLeft = 50,
}: {
  label: string;
  collectionId: string;
  containerRef: any;
  posTop?: number;
  posLeft?: number;
}) => {
  const [showComponent, setShowComponent] = useState<boolean>(false);

  function CollectionCardPortal() {
    return ReactDOM.createPortal(
      <CollectionCard
        containerRef={containerRef}
        collectionId={collectionId}
        posTop={posTop}
        posLeft={posLeft}
      />,
      document.getElementById("pageRoot") as any
    );
  }

  return (
    <>
      {/* When the span is hovered over, set "showComponent" to true; when it is no longer hovered over, set it to false */}
      <div
        className="relative overflow-visible"
        style={{ width: "calc(100% - 20px)" }}
        onMouseEnter={(e: any) => {
          e.stopPropagation();
          setShowComponent(true);
        }}
        onMouseLeave={(e: any) => {
          e.stopPropagation();
          setShowComponent(false);
        }}
      >
        <p
          className={"truncate text-sm text-neutral-600 dark:text-neutral-400"}
        >
          {label}
        </p>
        {showComponent ? <>{CollectionCardPortal()}</> : null}
      </div>
    </>
  );
};

const CollectionCard = ({
  collectionId,
  containerRef,
  posTop,
  posLeft,
}: {
  collectionId: string;
  containerRef: any;
  posTop: number;
  posLeft: number;
}) => {
  const [collection, setCollection] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const containerPosition = containerRef?.current?.getBoundingClientRect?.();

  useEffect(() => {
    if (!collectionId) return;
    fetchCollection();
  }, []);

  const fetchCollection = async () => {
    try {
      const result = await readCollection(collectionId as string);
      setCollection(result);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        top: `${containerPosition.top + posTop + scrollY}px`,
        left: `${containerPosition.left - posLeft}px`,
      }}
      className={`w-64 bg-white dark:bg-neutral-900 dark:border-neutral-800 rounded-2xl border shadow-xl text-neutral-900 absolute z-30`}
    >
      {loading ? (
        <div className="flex flex-col items-center justif-center py-10">
          <Loader
            className="animate-spin transition duration-300 text-neutral-400 dark:text-neutral-600"
            size={32}
          />
        </div>
      ) : (
        <div className="w-full h-full relative">
          <div
            className="w-full bg-cover bg-center rounded-t-2xl h-16"
            style={{ backgroundImage: `url(${collection?.imageUrl})` }}
          ></div>
          <div className="py-3 px-4">
            <div className="flex items-start justify-between">
              <div className="block relative">
                <p className="truncate dark:text-white w-44 block">
                  {collection?.name}
                </p>
                <div className="flex items-center text-neutral-600 dark:text-neutral-300 gap-2 text-xs">
                  <p>
                    {formatThousandsNumber(collection?.stats?.supplyTotal)}{" "}
                    Items
                  </p>
                  <span className="w-1 h-1 bg-neutral-400 rounded-full"></span>
                  <p>
                    {formatThousandsNumber(collection?.stats?.ownersTotal)}{" "}
                    Owners
                  </p>
                </div>
              </div>
              <MiniStat
                value={collection?.stats?.floorPrice?.toFixed(2)}
                currency={true}
                label={"Floor"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const formatThousandsNumber = (itemCount: number) => {
  if (itemCount >= 1000) {
    return (itemCount / 1000).toFixed(1) + "K";
  } else return itemCount;
};

const MiniStat = ({
  value,
  label,
  currency = false,
}: {
  value: string;
  label: string;
  currency?: boolean;
}) => {
  return (
    <div className="flex-shrink-0 justify-end text-right dark:text-white">
      <p className="flex items-center">
        {currency ? <Eth size={14} /> : null} {value}
      </p>
      <p className="text-xs dark:text-neutral-300">{label}</p>
    </div>
  );
};

export default CollectionHover;
