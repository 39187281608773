import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Loader } from "client/components/icons/loader";
import { Collection } from "shared/types/collection";
import { generateProjectDatabaseId } from "@/shared/artblocks";

interface ArtBlocksGenerator {
  contractAddress: string;
  projectId: string;
  aspectRatio?: number;
  tokenId: string;
  tokenHash: string;
  onLoaded?: () => void;
}

export const ArtBlocksGenerator = ({
  contractAddress,
  projectId,
  aspectRatio = 1,
  tokenId,
  tokenHash,
  onLoaded,
}: ArtBlocksGenerator) => {
  const containerRef = useRef<any>(null);
  const defaultImageHeight = containerRef?.current?.offsetHeight;
  const defaultImageWidth = containerRef?.current?.offsetWidth;
  const [frameHeight, setFrameHeight] = useState<number>(defaultImageHeight);
  const [frameWidth, setFrameWidth] = useState<number>(defaultImageWidth);
  const scriptContainer = useRef<any>(null);

  useLayoutEffect(() => {
    function updateSize() {
      setFrameHeight(
        containerRef.current.offsetHeight > 250
          ? containerRef.current.offsetHeight
          : 250
      );
      setFrameWidth(containerRef?.current?.offsetWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setFrameHeight(
      containerRef.current.offsetHeight > 250
        ? containerRef.current.offsetHeight
        : 250
    );
    setFrameWidth(containerRef?.current?.offsetWidth);
  }, [containerRef?.current?.offsetHeight, containerRef?.current?.offsetWidth]);

  let imageHeight = null;
  let imageWidth = null;
  if (aspectRatio < 1) {
    imageHeight = frameHeight;
    imageWidth = frameHeight * aspectRatio;
  } else {
    if (frameWidth > 1200) {
      // max width
      imageWidth = 1200;
      imageHeight = 1200 / aspectRatio;
    } else {
      imageWidth = frameWidth;
      imageHeight = frameWidth / aspectRatio;
    }

    // overwrite if image won't fit
    if (imageHeight > frameHeight) {
      // this image is too big to fit in this height, need to redo size height first
      imageHeight = frameHeight;
      imageWidth = frameHeight * aspectRatio;
    }
  }

  const id = generateProjectDatabaseId(contractAddress, projectId);

  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center"
      ref={containerRef}
    >
      {imageHeight && imageWidth ? (
        <iframe
          onLoad={onLoaded}
          src={`https://generator.artblocks.io/${id}/${tokenId}/${tokenHash}`}
          ref={scriptContainer}
          sandbox="allow-scripts allow-downloads"
          allow="xr-spatial-tracking"
          scrolling="no"
          key={frameHeight}
          style={{
            width: imageWidth,
            height: imageHeight,
            margin: "0 auto",
          }}
        ></iframe>
      ) : (
        <Loader className="animate-spin mx-auto duration-300 text-neutral-400" />
      )}
    </div>
  );
};

export default ArtBlocksGenerator;
