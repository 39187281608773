import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import ms from "ms";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import ReactTooltip from "react-tooltip";
import { useInterval } from "usehooks-ts";

import { ArtBlocksGenerator } from "@/client/components/common/ArtBlocksGenerator/ArtBlocksGenerator";
import CollectionHover from "@/client/components/common/CollectionHover";
import ListModal from "@/client/components/common/ListModal";
import PurchaseModal from "@/client/components/common/PurchaseModal";
import BidModal from "@/client/components/common/modal/BidModal";
import EthLabel from "@/client/components/frame-design-system/labels/EthLabel";
import { useAuth } from "@/client/components/common/AuthProvider";
import MarketIcon from "@/client/components/icons/market-icon";
import { shortenAssetName } from "@/client/lib/helpers";
import { Asset } from "shared/types/asset";
import { getBasePath } from "@/shared/config";

interface AssetCardComponent {
  id: string | undefined;
  name: string;
  contractAddress: string;
  tokenId: string;
  tokenHash?: string;
  imageUrl: string;
  contain: boolean;
  aspectRatio?: number;
  availablePrice?: number;
  availableMarket?: string;
  ownerAddress?: string;
  currentUserAddress?: string;
  isFlagged?: boolean;
  lastPrice: any;
  mintPrice?: number;
  showGenerator?: boolean;
  setGeneratorAsset?: any;
  generatorAvailable?: boolean;
  collection?: any;
  collectionId: string;
  rarityRank: any;
  showRanking: boolean;
  autoHideActionButton?: boolean;
  hideActions?: boolean;
  showCollection?: boolean;
  customContent?: any;
  onPurchaseModalOpenChanged?: (isOpen: boolean) => void;
}

const AssetCard = ({
  asset,
  collection,
  contain = false,
  showGenerator,
  setGeneratorAsset,
  generatorAvailable = false,
  showRanking = false,
  autoHideActionButton = true,
  showCollection = false,
  customContent = null,
  assetContract = null,
  hideActions = false,
  assetTokenId = null,
  onPurchaseModalOpenChanged,
}: {
  asset: Asset;
  collection?: any;
  contain?: boolean;
  showGenerator?: boolean;
  showRanking?: boolean;
  generatorAvailable?: boolean;
  autoHideActionButton?: boolean;
  setGeneratorAsset?: any;
  showCollection?: boolean;
  hideActions?: boolean;
  customContent?: any;
  assetContract?: string | null;
  assetTokenId?: string | null;
  onPurchaseModalOpenChanged?: (isOpen: boolean) => void;
}) => {
  const { address } = useAuth();

  return (
    <AssetCardComponent
      id={asset?._id}
      collection={collection}
      collectionId={asset?.collectionId}
      name={asset?.name || ""}
      tokenId={assetTokenId ? assetTokenId : asset.tokenId}
      tokenHash={asset.tokenHash}
      contractAddress={assetContract ? assetContract : asset.contractAddress}
      contain={contain}
      aspectRatio={asset.aspectRatio}
      imageUrl={asset?.imageThumbnailUrl || ""}
      availablePrice={
        asset?.floorAsk?.price ? asset?.floorAsk?.price : undefined
      }
      availableMarket={
        asset?.floorAsk?.sourceName ? asset?.floorAsk?.sourceName : undefined
      }
      isFlagged={asset?.isFlagged}
      ownerAddress={asset?.ownerAddress}
      currentUserAddress={address}
      lastPrice={asset?.mostRecentSale?.price}
      mintPrice={asset?.mint?.price}
      showGenerator={showGenerator}
      generatorAvailable={generatorAvailable}
      showRanking={showRanking}
      rarityRank={asset?.rarityRank}
      setGeneratorAsset={setGeneratorAsset}
      autoHideActionButton={autoHideActionButton}
      showCollection={showCollection}
      onPurchaseModalOpenChanged={onPurchaseModalOpenChanged}
      customContent={customContent}
      hideActions={hideActions}
    />
  );
};

export const AssetCardComponent = ({
  id,
  name,
  imageUrl: rawImageUrl,
  contractAddress,
  tokenId,
  tokenHash,
  contain = false,
  aspectRatio = 1,
  availablePrice,
  availableMarket,
  ownerAddress,
  currentUserAddress,
  isFlagged,
  lastPrice,
  mintPrice,
  collection,
  collectionId,
  showGenerator,
  setGeneratorAsset,
  generatorAvailable = false,
  showRanking,
  rarityRank,
  autoHideActionButton = true,
  showCollection = false,
  customContent = null,
  hideActions = false,
  onPurchaseModalOpenChanged,
}: AssetCardComponent) => {
  const RETRY_INTERVAL_MS = ms("65s");

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [listModalOpen, setListModalOpen] = useState(false);
  const [beforeLoad, setBeforeLoad] = useState<boolean>(true);
  const { width, height, ref } = useResizeDetector();
  const {
    width: containWidth,
    height: containHeight,
    ref: containRef,
  } = useResizeDetector();
  const [imageHeight, setImageHeight] = useState<number | null>(null);
  const [containImageHeight, setContainImageHeight] = useState<number | null>(
    null
  );

  const imagePlaceholder = `${getBasePath()}/image-placeholder.png`;
  const imageUrl = rawImageUrl || imagePlaceholder;

  useEffect(() => {
    setBeforeLoad(false);
  }, []);

  useEffect(() => {
    if (width) {
      const newHeight = width * (1 / aspectRatio);
      setImageHeight(newHeight);
    }
    determineContainImageSize();
  }, [width, height]);

  useEffect(() => {
    if (onPurchaseModalOpenChanged)
      onPurchaseModalOpenChanged(purchaseModalOpen);
  }, [purchaseModalOpen]);

  const animationClasses = beforeLoad
    ? "invisible opacity-0 translate-y-24"
    : "visible opacity-100 translate-y-0";

  const handleBuyNowClicked = () => {
    setPurchaseModalOpen(true);
  };

  const handleBidClicked = () => {
    setBidModalOpen(true);
  };

  const handleListForSaleClicked = () => {
    setListModalOpen(true);
  };

  const isOwner =
    ownerAddress?.toLowerCase() === currentUserAddress?.toLowerCase();

  const showBuyNow = !isOwner && !!availablePrice;
  const showBidButton = !isOwner;
  const showListForSale = currentUserAddress && isOwner && !availablePrice;

  const determineContainImageSize = () => {
    if (!containWidth || !containHeight) return;

    // work out if making full height will have the height breaking bounds
    const expectedHeight = containWidth * (1 / aspectRatio);
    const expectedWidth = expectedHeight * (1 * aspectRatio);

    if (expectedHeight > containHeight || expectedWidth > containWidth) {
      setContainImageHeight(containHeight);
    } else {
      setContainImageHeight(expectedHeight);
    }
  };

  return (
    <>
      <PurchaseModal
        tokenId={tokenId}
        contractAddress={contractAddress}
        open={purchaseModalOpen}
        setOpen={setPurchaseModalOpen}
      />

      <BidModal
        tokenId={tokenId}
        contractAddress={contractAddress}
        open={bidModalOpen}
        setOpen={setBidModalOpen}
      />

      <ListModal
        tokenId={tokenId}
        contractAddress={contractAddress}
        open={listModalOpen}
        setOpen={setListModalOpen}
      />

      <div
        ref={ref}
        className={`${animationClasses} border dark:border-neutral-700 overflow-hidden transform transition-all asset-card bg-white dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-white rounded-md w-full shadow-sm hover:shadow-lg duration-300 relative`}
      >
        <Link
          href={`/asset/${contractAddress}/${tokenId}`}
          className="cursor-pointer pointer-events"
        >
          {contain ? (
            <span
              ref={containRef}
              className="h-64 w-full bg-neutral-100 dark:bg-neutral-800 p-8 flex flex-col items-center justify-center"
            >
              <img
                className={`shadow-xl contained-asset transform transition-all anim-loading bg-loading-light dark:bg-loading-dark`}
                src={imageUrl}
                style={{
                  height: containImageHeight as number,
                }}
              ></img>
            </span>
          ) : (
            <div
              style={{
                height: imageHeight as number,
              }}
              className="asset-image-container w-full relative overflow-hidden"
            >
              <div className="anim-loading bg-loading-light dark:bg-loading-dark absolute w-full h-full top-0 left-0"></div>
              {showGenerator && tokenHash ? (
                <div className={`w-full h-full absolute top-0 left-0`}>
                  <ArtBlocksGenerator
                    contractAddress={collection.contractAddress}
                    projectId={collection.artBlocksProjectId}
                    aspectRatio={
                      collection.aspectRatio
                        ? Number(collection.aspectRatio)
                        : undefined
                    }
                    tokenId={tokenId}
                    tokenHash={tokenHash}
                  />
                </div>
              ) : (
                <>
                  <div
                    className={`asset-image bg-cover transition duration-500 bg-center w-full h-full absolute top-0 left-0`}
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                  ></div>
                  {rarityRank && showRanking ? (
                    <div className="bg-white text-black text-sm shadow-md bottom-2 left-2 absolute px-2 py-1 rounded-md">
                      Rank #{rarityRank}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          )}
          {customContent ? (
            customContent
          ) : (
            <div className="rounded-b-md">
              <div className="p-3">
                <div className="flex items-center justify-between">
                  <div style={{ width: "calc(100% - 40px)" }} className="block">
                    {showCollection ? (
                      <Link href={`/collections/${collectionId}`}>
                        <CollectionHover
                          label={getCollectionName(name)}
                          collectionId={collectionId}
                          containerRef={containRef}
                        />
                      </Link>
                    ) : null}

                    <p className="truncate text-p-lg">
                      {collectionId &&
                      collectionId === "650d8d8837184ae578fa21f5"
                        ? name
                        : shortenAssetName(name)}
                    </p>
                  </div>

                  <div
                    className="flex-shrink-0 flex items-center gap-1"
                    style={{ marginTop: -2 }}
                  >
                    {isFlagged ? (
                      <>
                        <ReactTooltip
                          id="flagged-tooltip"
                          backgroundColor="black"
                          className="flat-color"
                          effect="solid"
                          place="top"
                        />
                        <span
                          data-for="flagged-tooltip"
                          data-tip="This item has been flagged as suspicious"
                          className="bg-red-500 w-5 flex-shrink-0 h-5 text-white rounded-full flex-col text-sm items-center text-center justify-center"
                        >
                          !
                        </span>
                      </>
                    ) : null}
                    {availableMarket ? (
                      <MarketIcon name={availableMarket} size={20} />
                    ) : // <MarketIconWithPartner name={availableMarket} size={20} />
                    null}
                  </div>
                </div>

                <div className="flex justify-between items-end mt-2">
                  <div>
                    {availablePrice ? (
                      <EthLabel size="large" amount={availablePrice} />
                    ) : (
                      <p className="h-6"></p>
                    )}
                  </div>
                </div>
              </div>

              <div className="h-10 flex items-center">
                {lastPrice ? (
                  <p className="px-3 text-p-s gap-1 flex items-center text-neutral-600 dark:text-neutral-300">
                    Last Price:{" "}
                    <EthLabel amount={lastPrice} size="small" weight="normal" />
                  </p>
                ) : (
                  <>
                    {mintPrice ? (
                      <p className="px-3 text-p-s gap-1 flex items-center text-neutral-600 dark:text-neutral-300">
                        Minted for:{" "}
                        <EthLabel
                          amount={mintPrice}
                          size="small"
                          weight="normal"
                        />
                      </p>
                    ) : (
                      <p className="px-3 text-p-s flex items-center text-neutral-500">
                        Never sold
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Link>
        {collection && generatorAvailable ? (
          <>
            {!showGenerator ? (
              <button
                onClick={() => setGeneratorAsset(id)}
                style={{ top: (imageHeight as number) - 55, right: 16 }}
                className="generator w-10 h-10 flex flex-col items-center justify-center rounded-full shadow invisible opacity-0 transition-all duration-300 absolute bg-white z-10"
              >
                <PlayIcon className="gb h-5 w-5 text-neutral-400 transition duration-300" />
              </button>
            ) : (
              <button
                onClick={() => setGeneratorAsset(null)}
                style={{ top: (imageHeight as number) - 55, right: 16 }}
                className="generator w-10 h-10 flex flex-col items-center justify-center rounded-full shadow invisible opacity-0 transition-all duration-300 absolute bg-white z-10"
              >
                <PauseIcon className="gb h-5 w-5 text-neutral-400 transition duration-300" />
              </button>
            )}
          </>
        ) : null}

        {hideActions ? null : (
          <>
            {showBuyNow || showBidButton ? (
              <div className={`${getButtonClass(autoHideActionButton)}`}>
                {showBidButton ? (
                  <button
                    className={`assetcard-bid ${
                      !showBuyNow
                        ? "w-full text-center"
                        : "w-20 flex-shrink-0 hover:w-1/2"
                    } transition-all duration-300 bg-neutral-100 dark:bg-neutral-700 dark:text-white text-neutral-900 h-full text-center px-4`}
                    onClick={() => handleBidClicked()}
                  >
                    {!showBuyNow ? "Place Offer" : "Offer"}
                  </button>
                ) : null}
                {showBuyNow ? (
                  <button
                    className="assetcard-buy truncate bg-neutral-900 dark:bg-white dark:text-neutral-900 transition hover:bg-neutral-700 dark:hover:bg-neutral-300 w-full h-full text-center px-4"
                    onClick={() => handleBuyNowClicked()}
                  >
                    Buy now
                  </button>
                ) : null}
              </div>
            ) : null}
            {!showListForSale ? null : (
              <div className={getButtonClass(autoHideActionButton)}>
                <button
                  onClick={() => handleListForSaleClicked()}
                  className="assetcard-buy bg-neutral-900 dark:bg-white dark:text-neutral-900 transition hover:bg-neutral-700 dark:hover:bg-neutral-300 w-full h-full text-center px-4"
                >
                  List for Sale
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const getCollectionName = (str: string) => {
  let splits = str.split("#");
  if (splits.length > 0) {
    return splits[0];
  }

  return str;
};

const getButtonClass = (autoHide: boolean) => {
  const base =
    "w-full flex items-center transition-all duration-300 absolute bottom-0 right-0 left-0 text-white bg-red-100 z-10";
  return autoHide
    ? `${base} buy-now-hidden h-0 invisible opacity-0`
    : `${base} h-10`;
};

export default AssetCard;
